<template>
    <v-card>
        <v-card-title primary-title>
            <v-icon left large>mdi-basket-fill</v-icon><span class="headline">Buscar en bodega</span>
            <v-spacer></v-spacer>
            <v-btn v-if="canPrint" color="primary" depressed @click="pdfListado()"><v-icon left>mdi-printer</v-icon>Imprimir</v-btn>
            
        </v-card-title>
        <v-divider></v-divider>
        
        <v-card-text>
            <v-switch
            v-model="verRecolectados"
            label="Mostrar recolectados"
            @change="getDataFromApi"
            ></v-switch>
            <v-row>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="item in productos" :key="item.id">
                    <v-lazy
                        :options="{
                        threshold: .5
                        }"
                        min-height="100"
                        transition="fade-transition"
                    >

                        <ProductoPendiente v-bind:producto="item" v-on:cambiarestatus="cambiarEstatus(item)"></ProductoPendiente>
                    </v-lazy>
                </v-col>
            </v-row>

        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>
        <DialogEspera :dialogEspera="dialogEspera"></DialogEspera>

        </v-card-text>
    </v-card>
</template>

<script>
    import platform from 'platform-detect'
    import ApiService from '@/services/ApiService.js'
    import SnackbarMensajes from '@/components/SnackbarMensajes.vue'
    import DialogEspera from '@/components/DialogEspera.vue'
    import {
        validationMixin
    } from 'vuelidate'
    import ProductoPendiente from '@/components/ProductoPendiente.vue'

    export default {
        name: 'BuscarEnBodega',
        mixins: [validationMixin],
        components: {
            SnackbarMensajes,
            DialogEspera,
            ProductoPendiente
        },
        data: () => ({
            verRecolectados: false,
            productos: [],
            dialogEspera: false,
            sb: {
                mostrar: false,
                mensaje: '',
                color: 'success',
                timeout: 3000,
                multiline: false,
            },
        }),
        computed: {
            canPrint() {
                return !(platform.ios && platform.pwa);

            },
            labelSwitchRecolectados() {
                return this.verRecolectados ? 'Mostrar recolectados' : 'Pendientes'
            }
        },
        methods: {
            pdfListado() {
                this.dialogEspera = true;
                ApiService.getPdf(this.$store.getters.token, '/pedidos/pdfbuscarenbodega',{})
                    .then(response => {
                        if (response.status == 200) {
                            this.dialogEspera = false;

                            var popup = window.open();   
                            var link = document.createElement('a');  
                            link.setAttribute('href', 'data:application/pdf;base64, ' + response.data );  
                            link.setAttribute('download', 'buscarenbodega_' + Date.now() + '.pdf');  
                            popup.document.body.appendChild(link);   
                            link.click();

                        } else {
                            this.showMensaje(
                                `Error al obtener pdf ${response.status} - ${response.statusText} `,
                                'error', true)
                        }
                    })
                    .catch(error => {
                        if (error.response) this.showMensaje(
                            `Error al obtener pdf ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                        else this.showMensaje(`Error al obtener pdf ${error.message}`, 'error', true)
                        this.dialogEspera = false
                    })
                    .finally(() => (this.dialogEspera = false))
            },
            cambiarEstatus(producto) {
                const data = {
                    recolectado_bodega: producto.recolectado_bodega
                }

                this.dialogEspera = true;
                ApiService.setProductoPedidoRecolectadoBodega(this.$store.getters.token, producto.id, data)
                    .then(response => {
                        // 201 => Created :: 200 => OK Updated
                        if (response.status == 200) {
                            this.getDataFromApi();
                            this.showMensaje(`Producto actualizado ID: ${response.data.id}`, 'success', false)
                        } else {
                            this.showMensaje(
                                `Error al actualizar estatus ${response.status} - ${response.statusText}`,
                                'error', true)
                        }
                    })
                    .catch(error => {
                        this.showMensaje(
                            `Error ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                    })
                    .finally(() => {
                        this.dialogEspera = false
                    })
            },
            getDataFromApi() {
                this.isLoading = true
                ApiService.getProductosPendientes(this.$store.getters.token, this.verRecolectados)
                    .then(response => {
                        if (response.status == 200) {
                            this.productos = response.data;
                            this.isLoading = false;
                        } else {
                            this.showMensaje(
                                `Error al obtener listado ${response.status} - ${response.statusText} `,
                                'error', true)
                        }
                    })
                    .catch(error => {
                        if (error.response) this.showMensaje(
                            `Error al obtener listado ${error.response.status} - ${error.response.statusText}`,
                            'error', true)
                        else this.showMensaje(`Error al obtener listado ${error.message}`, 'error', true)
                        this.isLoading = false
                    })
                    .finally(() => (this.isLoading = false))
            },
            showMensaje(mensaje, color, multiline) {
                this.sb.mensaje = mensaje
                this.sb.multiline = multiline
                this.sb.color = color
                this.sb.mostrar = true
            },

        },
        mounted() {
            this.getDataFromApi()
        },
        created() {}
    }
</script>