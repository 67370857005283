<template>
    <v-card class="mx-auto" :color="color" dark>
        <v-card-title>
            <v-icon large left>
                mdi-cart-arrow-down
            </v-icon>
            <span class="title font-weight-light">Pedido {{ producto.idpedido }}</span>
        </v-card-title>

        <v-card-text class="title font-weight-bold">
            {{ producto.descripcion }}
        </v-card-text>

        <v-card-actions>
            <!-- <v-btn color="success" @click="producto.recolectado_bodega = !producto.recolectado_bodega">
                <v-icon left>mdi-target</v-icon>Encontrado
            </v-btn>
         -->
         <v-checkbox v-model="producto.recolectado_bodega" @change="$emit('cambiarestatus')" label="Encontrado"></v-checkbox>
                
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'ProductoPendiente',
        props: {
            producto: {
                descripcion: String,
                pedido: Number,
                vendedor: String,
                recolectado_bodega: Boolean
            }
        },
        computed:{
            color() {
                return this.producto.recolectado_bodega ? 'success' : 'orange darken-2'
            },
            iconoRecolectado(){
                if(!this.producto) return 'mdi-checkbox-blank-outline'
                else return this.producto.recolectado_bodega ? 'mdi-checkbox-ouline' : 'mdi-checkbox-blank-outline'
            }
        },
        data() {
            return {}
        },
        methods: {
            
        }
    }
</script>

<style>

</style>